import React from "react"
import style from "./Layout.module.scss";
import Header from "./Header";

export default ({name, children}) => {
  return (
    <div className={style.root}>
      <header>
        <Header name={name}/>
      </header>
      <main className={style.main}>
        {children}
      </main>
    </div>
  )
}
