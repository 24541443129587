import React from "react"
import { route } from 'preact-router';
import style from "./Header.module.scss"

const Header = ({name}) => {
  return (
    <div className={style.root}>
      <h2 className={style.logo} >
        <a href="/">
          <span>{name}</span>
        </a>
      </h2>
    </div>
  )
}

export default Header
