import React from "react"
import ReactDOM from "react-dom"
import Router from "preact-router";

import App from "./pages/App"

const Main = () => (
	<Router>
    <App path="/" />
	</Router>
);

const render = () => ReactDOM.render(
  <Main />,
  document.getElementById("app")
)

render()
