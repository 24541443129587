import React, {useState, useEffect} from "react"
import Layout from "../components/Layout";
import style from "./App.module.scss"
import {RadioGroup, Radio} from "react-radio-group"
import moment from 'moment';
require('moment-precise-range-plugin')


const App = (props) => {
  const [result, setResult] = useState(0)
  const [inputs, setInputs] = useState({
    single: "0",
    salary: "0",
    isMonthly: "1",
    pagas: "12",
    importe: "",
    start: "",
    end: "",
    stopInterests: moment().format("YYYY-MM-DD")
  })
  const [info, setInfo] = useState({
    diff: {},
    months: []
  })

  useEffect(() => {
    if(inputs.start) {
      const {result: newResult, diff, months} = calculate(inputs)
      setResult(newResult)
      setInfo({diff, months})
    }
  }, [inputs])

  const setValue = (id, value) => {
    let newInputs = {...inputs}
    newInputs[id] = value
    setInputs(newInputs)
  }

  const inputProps = {inputs, setValue}

  const notSingle = inputs.single === "0"

  return (
    <Layout name="CÁLCULO DE INTERESES 10% DEL ART. 29.3 ET">
      <div className={style.main}>
        <div className={style.wrapper}>
          <div className={style.section}>
            <label>Salario</label>
            <Input id="salary" name="1000,00" {...inputProps} />
          </div>
          <div className={style.section}>
            <label>Cantidad Unica?</label>
            <RadioGroup name="single" selectedValue={inputs.single} onChange={(val) => setValue("single", val)}>
              <label className={style.radio}><Radio value="0" />No</label>
              <label className={style.radio}><Radio value="1" />Si</label>
            </RadioGroup>
          </div>

          
          {notSingle && (
            <>
              <div className={style.section}>
                <label>Tipo de salario</label>
                <RadioGroup name="isMonthly" selectedValue={inputs.isMonthly} onChange={(val) => setValue("isMonthly", val)}>
                  <label className={style.radio}><Radio value="1" />Mensual</label>
                  <label className={style.radio}><Radio value="0" />Diario</label>
                </RadioGroup>
              </div>
              <div className={style.section}>
                <label>Cantidad periódica</label>
                <RadioGroup name="pagas" selectedValue={inputs.pagas} onChange={(val) => setValue("pagas", val)}>
                  <label className={style.radio}><Radio value="12" />12</label>
                  <label className={style.radio}><Radio value="14" />14</label>
                </RadioGroup>
              </div>
            </>
          )}

          <div className={style.fieldset}>
            <div className={style.section}>
              <label>Fecha de inicio</label>
              <Input id="start" name="31.12.2020" type="date" {...inputProps} />
            </div>
            {notSingle && (
              <div className={style.section}>
                <label>Fecha de finalizacion</label>
                <Input id="end" name="31.12.2040" type="date" inputs={inputs} setValue={setValue}/>
              </div>
            )}
          </div>
          <div className={style.section}>
            <label>Fetcha fin del cómputo de intereses</label>
            <Input id="stopInterests" name="31.12.2040" type="date" inputs={inputs} setValue={setValue}/>
          </div>
          <h2 className={style.result}>Intereses: {result}</h2>
          {!!info.diff.years && (
            <>
              {!!info.months.length && <b>{info.months.length} meses con intereses durante:</b> }
              <p>
              {info.diff.years} años, <br />
              {info.diff.months} meses,<br />
              {info.diff.days} dias<br />
              </p>
              {!!info.months.length && (
                <>
                  <b>Intereses por mes:</b>
                  <ul>{info.months.slice(0, 500).map(m => <li>{m}</li>)}</ul>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={style.footer}>
        Concepto: María Virtudes Molina Palma<br/>
        Programa: Nicolas Almagro Tonne, <a href="https://nixo.no">Nixo</a>
      </div>
    </Layout>
  )
}

const calculate = (inputs) => {
  const {single, salary: salaryString, pagas, start: startVal, end: endVal, isMonthly, stopInterests} = inputs
  let salary = parseFloat(salaryString)
  // hoy
  const today = moment(stopInterests)
  // calcular deste el principio del mes de fetcha selectionada
  const start = single === "1" ? moment(startVal) : moment(startVal).startOf("month")
  let result = 0
  let months = []
  let diff = 0

  if(single === "1") {
    diff = getTimeDiff(start, today, false)
    result = monthlyCalc(diff, salary)
  } else {
    // calcular hasta el fin del mes de fetcha selectionada
    const end = moment(endVal).endOf("month")
    let currentStart = start.clone()
    if(isMonthly === "0") salary = (salary * 365) / 12
    while (currentStart.isBefore(end)) {
      const diff = getTimeDiff(currentStart, today)
      const thisMonth = monthlyCalc(diff, salary)
      const month = moment(currentStart).month() + 1
      result += thisMonth
      months.push(month + ": " + nicePrice(thisMonth))

      
      if(pagas === "14" && (month === 6 || month === 12)) {
        result += thisMonth
        months.push(month + ": " + nicePrice(thisMonth))
      }

      currentStart.add(1, "month")
      if(diff.years > 100) currentStart.add(999999, "years")
    }

    diff = getTimeDiff(start, today)
  }
  
  ////////////////////////////////////
  result = nicePrice(result)

  
  return {result, diff, months}
}

const monthlyCalc = (diff, salary) => {
  let result = 0
  let {years, months, days} = diff
  result += years * yearly * salary
  result += months * monthly * salary
  result += days * daily * salary
  return result
}

// todo: start from the start of the next month
const getTimeDiff = (start, end, addOne = true) => {
  const countStart = addOne ? start.clone().add(1,'month') : start
  return moment.preciseDiff(countStart, end, true); 
}

const nicePrice = price => (Math.round(price * 100) / 100).toLocaleString('es') + " €"

const yearly = 0.1
const monthly = yearly / 12
const daily = yearly / 360

const Input = ({id, name, type, inputs, setValue}) => (
  <input
    value={inputs[id]} onChange={({target}) => setValue(id, target.value)} 
    placeholder={name} 
    type={type || "number"}
    className={style.input} 
    name={id}
    key={id}
    step="0.01"
    min="0"
  />
)


export default App
